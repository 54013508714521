import firebase from "firebase/app";
import Sentry from "@/plugins/sentry";
import { EncryptableData } from "encrypted";

export const uploadData = async (
  storageRef: firebase.storage.Reference,
  docData: EncryptableData,
  ownerId: string
): Promise<firebase.storage.Reference | any> => {
  return new Promise((resolve, reject) => {
    if ("encryption" in docData || "blocks" in docData) {
      const metadata = {
        contentType: "application/json",
        customMetadata: {
          owner: ownerId
        }
      } as firebase.storage.UploadMetadata;

      // Upload file and metadata
      const blob = new Blob([JSON.stringify(docData)], {
        type: "application/json"
      });

      const uploadTask: firebase.storage.UploadTask = storageRef.put(
        blob,
        metadata
      );
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function(snapshot: firebase.storage.UploadTaskSnapshot) {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              break;
            case firebase.storage.TaskState.RUNNING:
              break;
          }
        },
        function(error: Error) {
          Sentry.captureException(error);
          reject(error);
        },
        function() {
          // Upload completed successfully, now we can get the download URL
          resolve(uploadTask.snapshot.ref);
        }
      );
    } else {
      reject();
    }
  }).catch(e => {
    Sentry.captureException(e);
  });
};

export const downloadData = async (
  storageRef: firebase.storage.Reference,
  fileUrl?: string
): Promise<EncryptableData | any> => {
  const url = fileUrl ? fileUrl : await storageRef.getDownloadURL();
  return new Promise((resolve, reject) => {
    // This can be downloaded directly:
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = async function(event) {
      try {
        const blob = xhr.response;
        const jsonString = await blob.text();
        resolve(JSON.parse(jsonString));
      } catch (e) {
        Sentry.captureException(e);
        reject();
      }
    };
    xhr.onerror = function(e) {
      Sentry.captureException(e);
    };
    xhr.open("GET", url);
    xhr.send();
  }).catch(e => {
    Sentry.captureException(e);
  });
};
