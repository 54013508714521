import { EncryptableData } from "encrypted";
import { uuid } from "vue-uuid";
import { ImagesBlockConfig } from "@/options/imageOptions";
import {
  CheckboxBlockConfig,
  dailyJournalContent,
  dailyQuotes,
  JournalBlockConfig,
  missionStatementContent,
  missionStatementTitle,
  mydailyRoutineTitle,
  mydailyRoutineItems,
  myValuesContent,
  myValuesTitle,
  myYearGoalaContent,
  QuotesBlockConfig,
  TextBlockConfig,
  todaysPrioritiesTitle,
  todaysPrioritiesItems,
  myYearGoalsTitle,
  myRelationshipVisionContent,
  myRelationshipVisionTitle,
  dailyJournalTitle,
  dailyQuotesTitle,
  myVisualizationTitle,
  newDailyQuotes
} from "@/options/editorOptions";
import { imagesArray } from "./imagesArrayOptions";

export interface Colors {
  primary?: string;
  secondary?: string;
  accent?: string;
}

export interface BoardConfig extends EncryptableData {
  version: string;
  id: string;
  colors?: Colors;
  background?: string;
  fontFamily?: string;
  blocks: [
    ImagesBlockConfig,
    TextBlockConfig,
    TextBlockConfig,
    TextBlockConfig,
    CheckboxBlockConfig,
    CheckboxBlockConfig,
    TextBlockConfig,
    JournalBlockConfig,
    QuotesBlockConfig
  ];
}

export const BoardConfigVersion = "4";

const NewBoardConfig = {
  version: BoardConfigVersion
} as BoardConfig;

export const NewPersonalBoardConfig = {
  ...NewBoardConfig,
  blocks: [
    {
      type: "images",
      blockId: uuid.v4(),
      assignment: "images_field",
      viewportTransform: [1, 0, 0, 1, 0, 0],
      zoom: 1,
      title: myVisualizationTitle,
      images: !window.Cypress ? imagesArray : []
    } as ImagesBlockConfig,
    {
      type: "text",
      blockId: uuid.v4(),
      assignment: "mission_statement",
      textType: "normal",
      title: missionStatementTitle,
      content: missionStatementContent
    } as TextBlockConfig,
    {
      type: "text",
      assignment: "values",
      blockId: uuid.v4(),
      textType: "normal",
      title: myValuesTitle,
      content: myValuesContent
    } as TextBlockConfig,
    {
      type: "text",
      blockId: uuid.v4(),
      assignment: "year_goals",
      textType: "normal",
      title: myYearGoalsTitle,
      content: myYearGoalaContent
    } as TextBlockConfig,
    {
      type: "text",
      blockId: uuid.v4(),
      assignment: "daily_routine",
      textType: "progress",
      history: [],
      title: mydailyRoutineTitle,
      content: ``,
      items: mydailyRoutineItems,
      removeDone: false
    } as CheckboxBlockConfig,
    {
      type: "text",
      blockId: uuid.v4(),
      assignment: "today_priorities",
      textType: "progress",
      history: [],
      title: todaysPrioritiesTitle,
      content: ``,
      items: todaysPrioritiesItems,
      removeDone: true
    } as CheckboxBlockConfig,
    {
      type: "text",
      blockId: uuid.v4(),
      assignment: "relationship_vision",
      textType: "normal",
      title: myRelationshipVisionTitle,
      content: myRelationshipVisionContent
    } as TextBlockConfig,
    {
      type: "journal",
      blockId: uuid.v4(),
      assignment: "daily_journal",
      template: dailyJournalContent,
      title: dailyJournalTitle,
      content: dailyJournalContent,
      /**
       * To debug/enhance on newly created boards:
       * import { getFakePosts } from "@/helpers/editorsHelpers" // at the top, then:
       */
      // posts: [...getFakePosts()]
      posts: []
    } as JournalBlockConfig,
    {
      type: "quotes",
      assignment: "daily_quotes",
      blockId: uuid.v4(),
      title: dailyQuotesTitle,
      quotes: dailyQuotes.concat(newDailyQuotes)
    } as QuotesBlockConfig
  ]
} as BoardConfig;
